import { useState, useEffect } from 'react';
import apiPath from '../../constants/apiPath';
import helper from '../../constants/helper';
import Page from '../../components/Page';
import { Link as RouterLink } from 'react-router-dom';
import useTable from 'src/components/useTable';
import Controls from '../../components/control/Controls';
import { Search } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import Popup from 'src/components/Popup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
  Stack,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Breadcrumbs,
  Link,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  Toolbar,
  InputAdornment,
  CircularProgress
} from '@mui/material';
import { Backdrop } from '@mui/material';
import BookingSlots from './BookingSlots'
import useSession from 'src/hooks/useSession';

export default function List() {
  let navigate = useNavigate();
  const [bookingsList, setBookingsList] = useState([]);
  const [collectionType, setCollectionType] = useState('all');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [openPopup, setOpenPopup] = useState(false);
  const session = useSession();
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });
  let [loading, setLoading] = useState(false);

  const getAllBookingsList = async () => {
    try {
      setLoading(true);
      const res = await helper.get(
        `${apiPath.get_bookings_list}?size=220&collection_type=${collectionType}&search_keyword=${searchKeyword}`,
        session
      );

      if (res.status === 200) {
        setLoading(false);
        if (res.data.success === true) {
          setBookingsList(res.data.data.docs);
        }
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const handleSearch = (e) => {
    setSearchKeyword(e.target.value);
    // setFilterFn({
    //   fn: (items) => {
    //     return target.value == ''
    //       ? items
    //       : items.filter((x) => x.collection_type.toLowerCase().includes(target.value));
    //   }
    // });
  };

  useEffect(() => {
    modulePermissions();
  }, []);

  useEffect(() => {
    getAllBookingsList();
  }, [collectionType, searchKeyword]);

  const [permission, setPermission] = useState({
    create: false,
    view: false,
    update: false,
    delete: false
  });

  const modulePermissions = () => {
    const modules = session?.profile?.user?.role?.role_rights || [];
    if (session?.profile?.user?.role?.id === 1) {
      setPermission({
        create: true,
        view: true,
        update: true,
        delete: true
      });
    } else {
      const module = 'bookings';
      let createObj = modules.find((obj) => obj.module.tbl_name === module && obj[`r_create`]);

      if (createObj) {
        permission.create = true;
      }
      let viewObj = modules.find((obj) => obj.module.tbl_name === module && obj[`r_view`]);
      if (viewObj) {
        permission.view = true;
      }
      let updateObj = modules.find((obj) => obj.module.tbl_name === module && obj[`r_update`]);
      if (updateObj) {
        permission.update = true;
      }
      let deleteObj = modules.find((obj) => obj.module.tbl_name === module && obj[`r_delete`]);
      if (deleteObj) {
        permission.delete = true;
      }
    }
  };

  const headeCells = [
    { id: 'Booking Id', label: 'Booking Id', disableSorting: true },
    { id: 'booking_hash', label: 'Booking Hash', disableSorting: true },
    { id: 'Prescription', label: 'Prescription', disableSorting: true },
    { id: 'user', label: 'User', disableSorting: true },
    { id: 'patient', label: 'Patient Name', disableSorting: true },
    { id: 'phone_number', label: 'Mobile No.', disableSorting: true },
    { id: 'source_type', label: 'Source', disableSorting: true },
    { id: 'admin_source', label: 'Admin Source', disableSorting: true },
    { id: 'admin_booking_source', label: 'Admin Booking Source', disableSorting: true },
    { id: 'updated_at', label: 'Booking Date', disableSorting: true },
    { id: 'booking_date_time', label: 'Booking Schedule', disableSorting: true },
    { id: 'collection_type', label: 'Collection', disableSorting: true },
    { id: 'city', label: 'City', disableSorting: true },
    { id: 'payment_mode', label: 'Payment Mode', disableSorting: true },
    { id: 'final_amount', label: 'Pay Amount', disableSorting: true },
    { id: 'status', label: 'status', disableSorting: true },
    { id: 'LedgerTransactionNo', label: 'Lab No.', disableSorting: true }
  ];

  {
    permission.view && headeCells.push({ id: 'action', label: 'Action', disableSorting: true });
  }

  const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
    bookingsList,
    headeCells,
    filterFn,
    searchKeyword
  );

  const handleBookingsView = async (item) => {
    navigate(`/bookings/details/${item.id}`);
  };

  return (
    <>
      <Page title={'Bookings List | Dr. B Lal'}>
        <ToastContainer bg="success" />
        <Backdrop
          sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Container maxWidth={false}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography variant="h4" gutterBottom>
                Bookings
              </Typography>

              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={RouterLink} to="/">
                  Dashboard
                </Link>
                <Typography color="text.primary">All Bookings List </Typography>
              </Breadcrumbs>
            </Grid>
          </Stack>

          <Toolbar>
            <Stack direction="row" style={{ justifyContent: 'space-between' }}>
              <Controls.Input
                style={{ width: '59%' }}
                label="Search Bookings"
                className={'searchInput'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  )
                }}
                onChange={handleSearch}
              />
              <FormControl sx={{ m: 1, width: 150, marginTop: '0%' }}>
                <InputLabel id="demo-simple-select-label">Collection Type</InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Collection Type"
                  onChange={(e) => {
                    setCollectionType(e.target.value);
                  }}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="Home">Home</MenuItem>
                  <MenuItem value="Lab">Lab</MenuItem>
                </Select>
              </FormControl>
              <Controls.Button
                text="Change time slots"
                variant="outlined"
                startIcon={<AddIcon />}
                className={'newButton'}
                onClick={() => {
                  setOpenPopup(true);
                }}
              />
            </Stack>
          </Toolbar>

          <TblContainer>
            <TblHeader />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.LisBookId}</TableCell>
                  <TableCell>{item.booking_hash}</TableCell>
                  <TableCell>{item.prescriptions_id ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{item?.user?.fullname}</TableCell>
                  <TableCell>{item.user_family_member.fullname}</TableCell>
                  <TableCell>{item.user_family_member.phone_number}</TableCell>
                  <TableCell>{item?.source_type ? item?.source_type : "-"}</TableCell>
                  <TableCell>{item?.admin_user_info ? JSON.parse(item.admin_user_info).fullname : "-"}</TableCell>
                  <TableCell>{item?.admin_booking_source ? item?.admin_booking_source?.source : "-"}</TableCell>
                  <TableCell>{item?.current_date_time ? moment(item.current_date_time).format("DD/MM/YYYY hh:mm A") : moment(item.updated_at).format('DD/MM/YYYY hh:mm A')}</TableCell>
                  <TableCell>
                    {item.collection_type === 'Home'
                      ? moment(item.booking_date_time).format('DD/MM/YYYY hh:mm A')
                      : moment(item.booking_date_time).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>{item.collection_type}</TableCell>
                  <TableCell>{item.city.CityName}</TableCell>
                  <TableCell>{item.payment_mode}</TableCell>
                  <TableCell>{parseFloat(item.total_member_amount) + parseFloat(item.pickup_charge)}</TableCell>
                  <TableCell>{item.status}</TableCell>
                  <TableCell>{item.LedgerTransactionNo ? item.LedgerTransactionNo : "-"}</TableCell>
                  {permission.view && (
                    <TableCell>
                      <Controls.ActionButton color="primary">
                        <RemoveRedEyeIcon
                          fontSize="small"
                          onClick={() => {
                            handleBookingsView(item);
                          }}
                        />
                      </Controls.ActionButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </Container>
      </Page>
      <Popup title={'Booking Management'} openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <BookingSlots loading={loading} setLoading={setLoading} session={session} setOpenPopup={setOpenPopup} />
      </Popup>
    </>
  );
}
